import { SxProps } from "@mui/material";
import { CustomContentProps, SnackbarContent } from "notistack"
import React, { ReactNode } from "react";
import { secondaryLight60 } from "XJumpTheme";

interface BaseCustomContentProps extends CustomContentProps {
    children: ReactNode
}

export const styleToastComponent: SxProps =
{
    px: '24px',
    borderRadius: '2px',
    borer: '2px solid ' + secondaryLight60,
    display: 'flex' ,
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    pl: '16px'
}

export const BaseToastComponent = React.forwardRef<HTMLDivElement, BaseCustomContentProps>((props, ref) => {
    const {
        id,
        message,
        children,
        ...other
    } = props

    const styles = {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return <SnackbarContent ref={ref} role="alert" {...other} style={styles.root}>
        {children}
    </SnackbarContent>
})

