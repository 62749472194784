import API from "api-axios"
import { QuestionType } from "Common/Enums"
import { QuestionContentDAO, QuestionSelection } from "Common/QuestionContentDAO"
import { TopicContentDAO } from "Common/TopicContentDAO"
import { QuestionContentModel } from "Model/QuestionContenModel"
import { TopicContentModel } from "Model/TopicContentModel"
import { LogError } from "./Logging"

class SystemQuestionsController {

    questions: QuestionContentModel[] = []
    topics: TopicContentModel[] = []

    private topicsListeners: ((topics: TopicContentModel[]) => void)[] = []
    private questionsListeners: ((question: QuestionContentModel[]) => void)[] = []
    
    destroy() {
        this.questions = []
        this.topics = []

        this.topicsListeners = []
        this.questionsListeners = []
    }

    init() {

        this.loadAllQuestions()
        this.loadAllTopics ()

    }

    private loadAllTopics = () => {
        try {
            API.get('/topics/all')

                .then(res => {

                    const data = res.data as TopicContentDAO[]
                    const topics: TopicContentModel[] = []

                    data.forEach(t => {

                        const newT = new TopicContentModel()

                        newT.id = t.id
                        newT.explanation = t.explanation ?? ""
                        newT.title = t.Title
                        newT.tutorialsVideoLink = t.tutorialVideoLink ?? ''
                        newT.AIPrompt = t.AIPrompt
                        newT.templateHint = t.TemplateHint
    
                        if (t.tutorial) newT.tutorials = JSON.parse(t.tutorial.toString())

                        topics.push(newT)
                    })

                    // checking if topics have changed 
                    if (this.topics.json() !== topics.json()) {
                        this.topics = topics
                        this.notifyTopicsListeners()
                    }
                })

        }
        catch (err) {

            LogError(err)

        }
    }

    private loadAllQuestions = () => {
        try {
            API.get('/question/all')

                .then(res => {

                    const data = res.data as QuestionContentDAO[]
                    const questions: QuestionContentModel[] = []

                    data.forEach(q => {

                        const newQ = new QuestionContentModel()

                        newQ.id = q.id
                        newQ.explanation = q.explanation ?? ''
                        newQ.title = q.title ?? ''
                        newQ.type = q.type! as QuestionType
                        newQ.tutorialsVideoLink = q.tutorialVideoLink ?? ''
                        newQ.multiAnswers = q.multiAnswers ? JSON.parse(q.multiAnswers!) as QuestionSelection[] : [] as QuestionSelection[]
                        newQ.AIPrompt = q.AIPrompt
                        newQ.Topic = q.Topic
                        newQ.Segment = q.Segment

                        if (q.QusetionTutorials) newQ.tutorials = JSON.parse(q.QusetionTutorials.toString())

                        questions.push(newQ)
                    })

                    // checking if questions have changed 
                    if (this.questions.json() !== questions.json()) {
                        this.questions = questions
                        this.notifyQuestionsListeners()
                    }
                })

        }
        catch (err) {

            LogError(err)

        }
    }


    // MARK: - Access 

    AddQuestion = (Q: QuestionContentModel) => {

        this.questions.push(Q)
        this.notifyQuestionsListeners()

    }

    AddTopic = (Q: TopicContentModel) => {

        this.topics.push(Q)
        this.notifyTopicsListeners()

    }

    GetQuestion = (id: number | undefined): QuestionContentModel | undefined => {

        if (id) {
            return this.questions.find(x => x.id === id)
        }
        else {
            return undefined
        }
    }

    GetTopic = (id: number | undefined): TopicContentModel | undefined => {

        if (id) {
            return this.topics.find(x => x.id === id)
        }
        else {
            return undefined
        }
    }


    // MARK: - Updates 

    SubscribeOnTopics(listener: (topics: TopicContentModel[]) => void) {

        this.topicsListeners.push(listener)

    }
    
    UnsubscribeFromTopics(listener: (topics: TopicContentModel[]) => void) {

        this.topicsListeners = this.topicsListeners.filter((l) => l !== listener)

    }
    
    private notifyTopicsListeners() {

        this.topicsListeners.forEach((listener) => listener(this.topics));

    }

    SubscribeOnQuestions(listener: (questions: QuestionContentModel[]) => void) {

        this.questionsListeners.push(listener)

    }
    
    UnsubscribeFromQuestions(listener: (questions: QuestionContentModel[]) => void) {

        this.questionsListeners = this.questionsListeners.filter((l) => l !== listener)

    }
    
    private notifyQuestionsListeners() {

        this.questionsListeners.forEach((listener) => listener(this.questions));

    }

}


export const SystemQuestions = new SystemQuestionsController()