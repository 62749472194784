import API from "api-axios"
import { PitchDAO } from "Common/PitchDAO"
import { ScriptDAO } from "Common/ScriptDAO"
import { useAlert } from "hooks/UseAlert"

import { unpackPitchFromDAO } from "hooks/UsePitches"

import { PitchModel } from "Model/PitchModel"
import { LogError } from "./Logging"
import { SystemQuestions } from "./SystemQuestionsController"
import { SegmentStatus } from "Common/Enums"


export async function GetPitchesPendingManualReview(): Promise<PitchModel[]> {

    const questions = SystemQuestions.questions
    const pitches = (await API.get<PitchDAO[]>('/admin/pitch/manualReview')).data

    return pitches.map(p => {

        return unpackPitchFromDAO(p)

    })

}



export async function SendPitchToAIScript(pitch: PitchModel) {

    const questions = pitch.segments.flatMap((seg) => {
        return seg.topics.flatMap((topic) => {
            return topic.questions.map((q) => {
                return q.packQuestionDAO()
            })
        })
    })

    const data = {
        'pitch': pitch.packPitchDAO(),
        'questions': questions
    }

    try {

        const res = await API.post(`/pitch/${pitch.id}/prepareAIScript`, data)

        if (res.data.updated && pitch.id) {

            // update segments statuses for returned id 
            const ids = res.data.updated
            if (ids && ids instanceof Array) {
                ids.forEach(segmentId => {
                    const segment = pitch.segments.find(s => s.id === segmentId)
                    segment?.setStatus(SegmentStatus.pending)
                })
            }

            return true
        } else {
            return false
        }
    }

    catch (err) {
        LogError(err)
        return false
    }
}



export async function ApproveAIScripts(pitchId: number, scripts: ScriptDAO[]) {


    API.post(`/admin/pitch/${pitchId}/approveAIScript`, scripts)
        .then(() => {

            return

        })

        .catch((err) => {

            LogError(err)
            throw err
        })

}

export async function SendSegmnetToAI(segmentId: number, newPrompt: string): Promise<string> {



    return API.post(`/admin/segment/${segmentId}/redoAI`, { 'prompt': newPrompt })

        .then(response => {
            return response.data.text
        })
        .catch(err => {

            LogError(err)
            throw err
        })

}

export async function GetAnswerClarificationFromUser(pitchId: number, QuestionId: number, clarification: string): Promise<void> {

    const alertError = useAlert().APIError

    try {


        const response = await API.post(`/admin/pitch/${pitchId}/question/${QuestionId}/clarify`, { 'clarification': clarification })

        if (response.status === 200) return

        Promise.reject(response.statusText)
    }
    catch (err) {
        LogError(err)
    }



}
