import { useEffect, useState } from 'react'
import { PitchView } from 'Pages/Investor/PitchView'
import { useParams, useHistory } from 'react-router-dom'
import { usePitches } from 'hooks/UsePitches'
import XJBreadcrumbs from '../../UI Elements/XJBreadcrumbs'
import { PitchStatus } from 'Common/Enums'
import { Box, Grid, SxProps, Typography } from '@mui/material'
import { theme, Header, HeaderRightButtons, PageTitle, SVGStyle } from 'XJumpTheme'
import {  XJButton } from 'UI Elements/Buttons/XJButton'
import { PublicPitchModel } from 'Model/PublicPitchModel'
import { PublicPitchDAO } from 'Common/PublicPitchDAO'
import { ExplanationBox } from 'UI Elements/XJExplanationBox'
import { useSpinner } from 'UI Elements/XJSpinner'
import { useAuth } from 'hooks/UseAuth'
import { XJModal } from 'UI Elements/PopUps/XJModal'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAlert } from 'hooks/UseAlert'
import { RoutingController } from 'Controllers/RoutingController'
import { IconLink } from 'UI Elements/XJIcons'
import { ReactComponent as SuccessIcon} from '../../svg/successIcon.svg'
import { LinkState } from 'Pages/Investor/InvestorPitchView'


export function PitchGoLive() {

    const { pid } = useParams<any>()

    const history = useHistory()

    const [pitchData, setPitchData] = useState<PublicPitchDAO>()

    const [showSuccess, setShowSuccess] = useState(false)
    const [newLink, setNewLink] = useState('')

    const spinner = useSpinner()
    

    useEffect(() => {

        const pitchModel = new PublicPitchModel()

        spinner(true)
        pitchModel.getPitchById(pid).then(res => {
            spinner(false)
            setPitchData(res)
        })

    }, [pid])

    const emailVerified = useAuth().EmailVerifiedStatus

    const pitch = usePitches().getPitch(pid)

    useEffect(() => {
        if (pitch && (pitch.status === PitchStatus.live || pitch.status === PitchStatus.offline)) {
            history.push(RoutingController.Home())
        }
    }, [pitch, history])


    // MARK: - Actions 

    const onGoLive = () => {

        spinner (true)
        pitch.setStatusAndSave(PitchStatus.live)

        pitch.getNewShareLink("public link").then(res => {
            setNewLink(res.UniqueLink)
            spinner (false)
            setShowSuccess(true)
        }).catch ( () => 
        {
            spinner (false)
            
        })
    }

    const onModalClose = () => {
        history.push(RoutingController.Home())
    }


    // MARK: - Render 

    const Modal = () => {

        const alertSuccess = useAlert().success

        const onCopy = () => {
            alertSuccess('Link copied to the clipboard')
        }

        // MARK: - Render 

        const styles: { [id: string]: SxProps } = {
            root: {
                display: 'flex',
                gap: 16,
                justifyContent: 'space-around',
                flexDirection: 'column',
                paddingX: '140px'
            },
            icon: {
                display: 'flex',
                justifyContent: 'center'
            },
            content: {
                minWidth: '140px',
                display: 'flex',
                gap: 8,
                flexDirection: 'column'
            },
            innerContent: {
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: 8
            },
            copyLink: {
                ...theme.typography.link.secondary,
                cursor: 'pointer'
            },
            newLink: {
                ...theme.typography.secondary.bold,
                padding: 8,
                border: '1px solid lightgray'
            },
            buttons: {
                display: 'flex',
                justifyContent: 'center'
            }
        }

        return (
            <XJModal
                show={showSuccess}
                onClose={onModalClose}
                
            >

                <Box sx={styles.icon}> 
                  <SuccessIcon style={SVGStyle}/>
                </Box>

                <Box>
                    <Typography variant='h2'>
                        Pitch is live!
                    </Typography>
                </Box>

                <Box sx={styles.content}>

                    <Typography variant='h3'>
                        Pitch Live Link:
                    </Typography>

                    <Box sx={styles.innerContent}>

                        <Box sx={styles.newLink}>
                            {newLink}
                        </Box>

                        <CopyToClipboard text={newLink} onCopy={onCopy}>
                            <XJButton variant={'secondary'} iconLeft>
                                <IconLink />Copy
                            </XJButton>
                        </CopyToClipboard>

                    </Box>

                </Box>

                <Box sx={styles.buttons}>
                    <XJButton variant={'secondary'} onClick={onModalClose}>
                        OK
                    </XJButton>
                </Box>

            </XJModal >
        )
    }

    return <>
   
        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    <XJButton to={RoutingController.Home()} variant={'tertiary'}>
                        Cancel
                    </XJButton>

                    <XJButton disabled={emailVerified !== true} variant={'primary'} onClick={onGoLive}>
                        Go Live
                    </XJButton>
                </Box>
            </Grid>

            <Grid item xs={12} >
                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Pitch Preview
                    </Typography>
                </Box>

                <Box mb={32}>


                    <ExplanationBox>
                        Last step: this is a preview of the pitch the way investors will view it. Verify all looks good before going live.
                    </ExplanationBox>
                </Box>
            </Grid>



            <Grid item xs={12}>
                
                {pitchData && <PitchView pitch={pitchData} linkState={LinkState.goodLink}/>}
            </Grid>
        </Grid>

        <Modal />
    </>
}