
import { Box, Grid, SxProps, Typography, useMediaQuery } from "@mui/material"
import { CSSProperties, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { XJButton } from "UI Elements/Buttons/XJButton"
import XJImage from "UI Elements/XJImage"
import { useIntersectionObserver } from "usehooks-ts"
import { landingCopyPrimary, landingCopySecondary, landingH1, landingH2, landingH3, landingH4, landingLinkPrimaryDefault, landingLinkSecondaryDefault, lightPrimaryOverlay48, primaryDefault, secondaryDefault, secondaryDefaultOverlay8, SVGStyle, theme, whiteDefault, whiteOverlay16, whiteOverlay60 } from "XJumpTheme"

import ReactPlayer from "react-player"
import { LinkedInButton } from "Components/XJLinkedInButton"
import { LinkVariant, XJLink } from "UI Elements/XJLink"
import {  XJPitchuraLogo } from "UI Elements/XJPitchuraLogo"

import { ReactComponent as WhyPitchuraIcon } from '../../svg/whyPitchura.svg'
import { ReactComponent as WhyPitchuraScript } from '../../svg/whyPitchuraScript.svg'
import { ReactComponent as WhyPitchuraNetwork } from '../../svg/whyPitchuraNetwork.svg'
import { ReactComponent as LandingCostIsFree } from '../../svg/LandingCostIsFree.svg'
import { ReactComponent as WhyPitchuraVideo } from '../../svg/whyPitchuraVideo.svg'
import XJIconButton from "UI Elements/Buttons/XJIconButton"
import { Close48Icon, IconLogin, PlayerIconPlay } from "UI Elements/XJIcons"
import { RoutingController } from "Controllers/RoutingController"

const enum sections {
    Hero,
    Why,
    WhoWeAre,
    Package,
    Pricing,
    FAQ
}

export function Home() {

    const [sectionInView, setSectionInView] = useState(sections.Hero)

    const history = useHistory()

    const ObserverThreshold = 0.4

    const setActiveSection = (section: sections) => {
        setSectionInView(section)
    }

    const HeroRef = useIntersectionObserver({
        threshold: ObserverThreshold,
        onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Hero)
            }

        }
    }).ref

    const WhyPitchuraRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Why)
            }

        }
    }).ref

    const WhoAreWeRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.WhoWeAre)
            }

        }
    }).ref

    const PackageRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Package)
            }

        }
    }).ref

    const PricingRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Pricing)
            }

        }
    }).ref

    const FAQRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.FAQ)
            }

        }
    }).ref

    const ScrollIntoView = (section: sections) => {

        const el = document.querySelector<HTMLElement>(`[data-index='${section}']`)

        if (el) {
            el.scrollIntoView({ block: 'center', behavior: "smooth" })
        }
        
    }

    const isXS = useMediaQuery(theme.breakpoints.only('xs'))

    const [open, setOpen] = useState(false)

    const NavBar = () => {

        const LinkItem = (label: string, section: sections) => {

            const onClick = () => {
                setOpen(false)
                ScrollIntoView(section)
            }

            const styles = {
                link: {
                    textDecoration: 'none'
                }, 
                text: {
                    color: theme.palette.darkPrimary.dark50,
                    textDecoration: 'none'
                },
            }

            return (
                <Link onClick={onClick} style={styles.link} to={'#'}>
                    <Typography sx={styles.text} variant="h2">
                        {label}
                    </Typography>
                </Link>
            )
        }

        const TextItem = (label: string) => {
            const styles = {
                color: secondaryDefault,
                textDecoration: 'underline'
            }

            return <Typography sx={styles} variant="h2">{label}</Typography>
        }

        const styles = {
            wrapper: {
                position: 'absolute',
                left: 0,
                top: 0, 
                right: 0
            } as SxProps,
            root: {
                display: 'flex',
                justifyContent: 'center',
                px: { xs: 8, md: 24 },
                height: kNavbarHeight,
                alignItems: 'center',
                backgroundColor: whiteOverlay16,
                position: 'fixed',
                width: '100%',
                zIndex: theme.zIndex.appBar,
                boxShadow: theme.dropshadow.card.default
            } as SxProps,
            logo: {
                display: 'flex', 
                gap: 4, 
                alignItems: 'center', 
                position: 'absolute', 
                my: 'auto', 
                left: {xs: '16px', md: '48px'}
            } as SxProps,
            menu: {
                gap: 16, 
                display: {xs: 'none', lg: 'flex'}
            } as SxProps,
            buttons: {
                display: 'flex', 
                gap: 8, 
                justifyContent: 'end',
                position: 'absolute', 
                my: 'auto', 
                right: {xs: '8px', md: '48px'}
            } as SxProps,
            buttonLogin: {
                display: {xs: 'none', sm: 'block'}
            } as SxProps,
            buttonIcon: {
                display: {xs: 'flex', sm: 'none'},
                justifyContent: 'center',
                alignItems: 'center'
            } as SxProps
        }

        return (
            <Box sx={styles.wrapper}>
                <Box sx={styles.root}>
                    <Box sx={styles.logo}>
                        <XJPitchuraLogo variant='PNG+TypeH3' sx={{mx: 0}} />
                    </Box>

                    <Box sx={styles.menu}>
                        {sectionInView === sections.Hero ? TextItem("Home") : LinkItem("Home", sections.Hero)}
                        {sectionInView === sections.Why ? TextItem("Why pitchura") : LinkItem("Why pitchura", sections.Why)}

                        {sectionInView === sections.Package ? TextItem("how it works") : LinkItem("how it works", sections.Package)}
                        {sectionInView === sections.Pricing ? TextItem("Price") : LinkItem("Price", sections.Pricing)}
                        {sectionInView === sections.WhoWeAre ? TextItem("about us") : LinkItem("about us", sections.WhoWeAre)}
                        {/* {sectionInView === sections.FAQ ? TextItem("FAQ") : LinkItem("FAQ", sections.FAQ)} */}
                    </Box>

                    <Box sx={styles.buttons} > 
                        <XJButton to="/dashboard" variant={'tertiary'} sx={styles.buttonLogin}>Login</XJButton>
                        <XJIconButton onClick={() => {history.push(RoutingController.Home())}} variant={'tertiary'} sx={styles.buttonIcon}><IconLogin /></XJIconButton>
                        <XJButton to="/signup" variant={'secondary'}>Get Started</XJButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const kNavbarHeight = '48px'
    const sectionHeight = '852px'
    const screenHeight = `calc(100vh - ${kNavbarHeight})`
    const isLandscape = window.innerWidth / window.innerHeight > 1

    const Hero = () => {

        const kVideoUrl = 'https://videos.app.pitchura.ai/home.mov'

        const [showMobileVideo, setShowMobileVideo] = useState(false)

        // Fixes background on sides on actual mobile phones 
        document.body.style.backgroundColor = showMobileVideo ? 'black' : theme.palette.background.default

        useEffect(() => {
            document.onfullscreenchange = () => {
                if (!document.fullscreenElement) {
                    setShowMobileVideo(false)
                }
            }
        }, [])

        const onVideoClick = () => {
            setShowMobileVideo(true)
        }

        const onCloseVideo = () => {
            setShowMobileVideo(false)
        }
    

        // Render 

        const MobilePlayer = () => {

            const styles = {
                root: {
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    backgroundColor: 'black',
                    zIndex: 1002
                } as SxProps,
                player: {
                    minWidth: '100%',
                    minHeight: '100%'
                } as CSSProperties,
                button: {
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    zIndex: 1003
                } as SxProps
            } 

            return (
                <Box sx={styles.root}>
                    <ReactPlayer
                        config={playerConfig}
                        url={kVideoUrl}
                        controls
                        playing
                        width={'100%'}
                        height={'100%'}
                        style={styles.player}
                    />
                    <XJIconButton variant="secondary" sx={styles.button} onClick={onCloseVideo}>
                        <Close48Icon />
                    </XJIconButton>
                </Box>
            )
        }

        const styles = {
            root: {
                px: {xs: 8, sm: 'auto'},
                backgroundColor: theme.palette.white.overlay24,
                borderBottom: '2px solid ' + theme.palette.white.overlay60
            } as SxProps, 
            content: {
                pt: {xs: 'auto', md: '115px'},
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: { xs: '100%', md: '60%' },
                px: {xs: 0, sm: '71px'},
                maxWidth: {md: '618px'},
                mx: 'auto',
                gap: {xs: 16, sm: 16, md: 0},
                pb: {xs: '32px', md: '67px'},
                height: {xs: screenHeight, sm: screenHeight, md: sectionHeight},
                minHeight: {xs: '460px', sm: '500px', lg: '600px'},
                maxHeight: {xs: '824px', sm: '1073px', md: '1380px', lg: '900px'}
            } as SxProps,
            title: {
                ...(isXS ? landingH2 : landingH1), 
                color: secondaryDefault
            } as SxProps,
            text: {
                ...(isXS ? landingCopySecondary : landingCopyPrimary),
                color: theme.palette.darkPrimary.dark50,
                textAlign: 'center',
                mt: {xs: 0, sm: 0, md: 16},
                mb: 0
            } as SxProps,
            playerBox: {
                display: {xs: 'none', sm: 'none', md: 'flex'},
                justifyContent: 'center',
                mt: 32,
                width: {xs: '100%', sm: '100%', md: '600px'}
            } as SxProps,
            player: {
                width: '100%',
                height: '100%'
            } as CSSProperties,
            button: {
                display: {xs: 'flex', sm: 'flex', md: 'none'}
            } as SxProps
        }

        const playerConfig = {
            file: {
                attributes: {
                    playsInline: true
                }
            }
        }

        return <Box sx={styles.root}>

            {showMobileVideo && <MobilePlayer />}

            <Box sx={styles.content} ref={HeroRef} data-index={sections.Hero}>
                <Box sx={styles.title}>
                    create the video pitch investors want
                </Box>

                <Box sx={styles.text}>
                    Record a 5-minutes laser-focused video pitch in the format and terms investors expect - then easily share it with our network of VCs.
                </Box>

                <Box sx={styles.playerBox}>
                    <ReactPlayer
                        config={playerConfig}
                        url={kVideoUrl}
                        controls
                        width={'100%'}
                        height={'100%'}
                        style={styles.player}
                    />
                </Box>

                <XJButton variant="secondary" iconLeft sx={styles.button} onClick={onVideoClick}>
                    <PlayerIconPlay />
                    Watch video
                </XJButton>
            </Box>
        </Box>
    }

    const WhyPitchura = () => {

        const styles = {
            root: {
                width: '100%',
                backgroundColor: whiteOverlay16,
                height: {xs: screenHeight, lg: sectionHeight},
                minHeight: {xs: '460px', sm: '840px', md: '684px', lg: '600px'},
                maxHeight: {xs: '824px', sm: '1073px', md: '1380px', lg: '900px'},
                px: {xs: 8, sm: '71px', md: 'auto'},
                py: {xs: 0, sm: 32},
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            } as SxProps,
            content: {
                display: 'flex',
                width: {xs: '100%', lg: '538px'},
                flexDirection: 'column',
                gap: {xs: 32, sm: 16},
                justifyContent: 'center', 
                alignItems: {xs: 'center', sm: 'center', md: isLandscape ? 'flex-end' : 'center'},
                marginTop: {xs: '32px', md: isLandscape ? 'auto' : '32px'},
                marginLeft: 'auto',
                marginRight: {xs: 'auto', md: '16px'}
            } as SxProps,
            title: {
                ...(isXS ? landingH2 : landingH1), 
                textAlign: {xs: 'center', md: 'center'}
            } as SxProps,
            icon: {
                marginLeft: {xs: 'auto', md: isLandscape ? '16px' : 'auto'},
                marginRight: 'auto',
                alignItems: 'center',
                width: {xs: '85%', sm: '212px', md: isLandscape ? '100%' : '35%', lg: '538px'},
                overflow: 'hidden'
            } as SxProps,
            text: {
                ...(isXS ? landingCopySecondary : landingCopyPrimary), 
                color: theme.palette.darkPrimary.dark50
            } as SxProps
        }

        const Link = (props: {to: string, text: string}) => {
            return (
                <XJLink 
                    variant={LinkVariant.primary} 
                    sx={isXS ? landingLinkSecondaryDefault : landingLinkPrimaryDefault} 
                    absolute 
                    newWindow 
                    to={props.to}
                >
                    {props.text}
                </XJLink>
            )
        }

        return (
            <Box sx={styles.root} ref={WhyPitchuraRef} data-index={sections.Why}>
                <Grid container height={'100%'} alignContent={'center'}>
                    <Box sx={styles.icon} display={{xs: 'none', sm: 'flex', md: isLandscape ? 'none' : 'flex', lg: 'none'}}>
                        <WhyPitchuraIcon style={SVGStyle} />
                    </Box>

                    <Grid item xs={12} md={isLandscape ? 7 : 12} lg={6}>
                        <Box sx={styles.content}> 
                            <Box sx={styles.title}>
                                Preparation + Access
                            </Box>

                            <Box sx={styles.text}>
                                Raising money is hard. Founders many times lack access to investors, finding the right investors takes time, and communicating effectively takes practice.
                                <br/> <br/> 
                                Pitchura's AI pitch platform with its network of XX VCs prepares you to record a 5 minute video pitch that connects to right investors.
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={0} sm={0} md={isLandscape ? 5 : 0} lg={6} alignItems={'center'}>
                        <Box sx={styles.icon} display={{xs: 'none', sm: 'none', md: isLandscape ? 'flex' : 'none', lg: 'flex'}}>
                            <WhyPitchuraIcon style={SVGStyle} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const Package = () => {

        const styles = {
            root: {
                backgroundColor: secondaryDefault,
                width: '100%',
                height: {xs: 'auto', lg: sectionHeight},
                py: {xs: '64px', md: 'auto'},
                px: {xs: '32px', md: 'auto'},
                display: 'flex', 
                flexDirection: 'column',
                gap: 32 + 12,
                justifyContent: 'center',
                alignItems: 'center'
            } as SxProps,
            title: {
                ...(isXS ? landingH2 : landingH1), 
                color: whiteDefault
            } as SxProps,
            package: {
                display: 'flex', 
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: {xs: 16 + 12, md: 16}
            } as SxProps,
            packageItem: {
                position: 'relative',
                height: '500px',
                width: '310px'
            } as SxProps,
            headerNumberContainer: {
                position: 'absolute',
                width: '100%',
                top: '-24px',
            } as SxProps,
            headerNumber: {
                width: '48px',
                height: '48px',
                mx: 'auto',
                backgroundColor: primaryDefault,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            } as SxProps,
            headerNumberCopy: {
                ...landingH3,
                color: whiteDefault,
                width: '24px',
                height: '36px',
                textAlign: 'center',
                margin: 'auto'
            } as SxProps,
            packageCard: { 
                width: '100%', 
                height: '100%', 
                backgroundColor: secondaryDefaultOverlay8,
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                alignItems: 'center',
                justifyContent: 'flex-start', 
                px: 16, 
                pb: 16,
                pt: 24
            } as SxProps,
            icon: {
                pt: 5
            } as SxProps,
            packageText: {
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'center'
            } as SxProps,
            packageItemDescription: {
                ...landingCopySecondary,
                textAlign: 'justify',
                color: lightPrimaryOverlay48
            } as SxProps
        }

        return <>
            <Box sx={styles.root} ref={PackageRef} data-index={sections.Package}>
                <Box sx={styles.title}>
                    Pitchura:<br /> all-in-one solution
                </Box>

                <Box sx={styles.package}>
                    <Box sx={styles.packageItem} >
                        <Box sx={styles.packageCard}>
                            <Box sx={styles.headerNumberContainer}>
                                <Box sx={styles.headerNumber}>
                                    <Box sx={styles.headerNumberCopy}>1</Box>
                                </Box>
                            </Box>

                            <Box sx={styles.icon}>
                                <WhyPitchuraScript style={SVGStyle} />
                            </Box>

                            <Box sx={styles.packageText}>
                                <Box sx={{ ...landingH3, color: whiteDefault }}>
                                    Script
                                </Box>

                                <Box>
                                    <Typography sx={styles.packageItemDescription}>What to tell investors? What's more and less important? What terms to use?</Typography>
                                    <Typography sx={styles.packageItemDescription}>By asking you a series of questions about your venture, we'll develop an outline for your pitch and then use AI to turn it into a script, touching all the points investors look for.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={styles.packageItem}>
                        <Box sx={styles.packageCard}>
                            <Box sx={styles.headerNumberContainer}>
                                <Box sx={styles.headerNumber}>
                                    <Box sx={styles.headerNumberCopy}>2 </Box>
                                </Box>
                            </Box>

                            <Box sx={styles.icon}>
                                <WhyPitchuraVideo style={SVGStyle} />
                            </Box>

                            <Box sx={styles.packageText}>
                                <Box sx={{ ...landingH3, color: whiteDefault }}>
                                    Video
                                </Box>

                                <Box>
                                    <Typography sx={styles.packageItemDescription}>The video is securely recorded by Pitchura (no YouTube!).</Typography>
                                    <Typography sx={styles.packageItemDescription}>A teleprompter will play the script for you to read at an optimum speed.</Typography>
                                    <Typography sx={styles.packageItemDescription}>It's easy to make different video versions of the same pitch.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={styles.packageItem} >
                        <Box sx={styles.packageCard}>
                            <Box sx={styles.headerNumberContainer}>
                                <Box sx={styles.headerNumber}>
                                    <Box sx={styles.headerNumberCopy}>3 </Box>
                                </Box>
                            </Box>

                            <Box sx={styles.icon}>
                                <WhyPitchuraNetwork style={SVGStyle} />
                            </Box>

                            <Box sx={styles.packageText}>
                                <Box sx={{ ...landingH3, color: whiteDefault }}>
                                    VC Network
                                </Box>

                                <Box sx={styles.packageItemDescription} >
                                    <Typography sx={styles.packageItemDescription}>There are XX VCs in the Pitchura network, looking to invest in new companies.</Typography>
                                    <Typography sx={styles.packageItemDescription}>Expose your video pitch to some or all of the VCs in our network - or share with anyone else.</Typography>
                                </Box>
                            </Box>
                        </Box>    
                    </Box>
                </Box>
            </Box >
        </>
    }

    const Pricing = () => {

        const styles = {
            root: {
                width: '100%',
                backgroundColor: whiteOverlay16,
                borderBottom: '2px solid ' + theme.palette.white.overlay60,
                height: {xs: screenHeight, sm: screenHeight, lg: 'auto'},
                minHeight: {xs: '460px', sm: '840px', md: '684px', lg: '600px'},
                maxHeight: {xs: '824px', sm: '1073px', md: '1380px', lg: '900px'},
                py: {xs: 0, sm: 32},
                px: {xs: 'auto', lg: '10%'}
            } as SxProps,
            icon: {
                height: {xs: 'auto'},
                display: {xs: 'none', sm: 'flex'},
                marginTop: {xs: '32px', lg: 'auto'},
                marginLeft: 'auto',
                marginRight: {xs: 'auto', lg: '16px'},
                alignItems: 'center',
                justifyContent: 'center',
                width: {xs: '85%', sm: '212px', md: '35%', lg: '100%'}
            } as SxProps,
            content: {
                display: 'flex',
                width: {xs: '85%', lg: '100%'},
                height: {xs: 'auto', lg: '100%'}, 
                flexDirection: 'column',
                gap: {xs: 32, lg: 16},
                justifyContent: 'center', 
                marginTop: {xs: '32px', lg: 'auto'},
                marginBottom: 'auto',
                marginLeft: {xs: 'auto', lg: '32px'},
                marginRight: 'auto'
            } as SxProps,
            title: {
                ...(isXS ? landingH2 : landingH1), 
                textAlign: {xs: 'center', lg: 'left'}
            } as SxProps,
            text: {
                ...(isXS ? landingCopySecondary : landingCopyPrimary), 
                color: theme.palette.darkPrimary.dark50,
                textAlign: {xs: 'center', lg: 'left'}
            } as SxProps
        }

        return (
            <Box ref={PricingRef} data-index={sections.Pricing} sx={styles.root}>
                <Grid container height={'100%'} alignContent={'center'}>
                    <Grid item xs={12} lg={6}>
                        <Box sx={styles.icon}>
                            <LandingCostIsFree style={SVGStyle} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Box sx={styles.content}>
                            <Box sx={styles.title} >
                                It's free
                            </Box>

                            <Typography sx={styles.text}>Pitchura will never charge founders money.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const WhoAreWe = () => {

        const styles = {
            root: {
                width: '100%',
                height: {xs: 'auto', lg: sectionHeight},
                backgroundColor: theme.palette.white.overlay24,
                pt: 10                              // fix for nav bar covering over title 
            } as SxProps,
            content: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: {xs: 32, lg: 20},
                width: {xs: '85%', lg: '50%'},
                maxWidth: '763px',
                mx: 'auto',
                py: {xs: '32px', lg: 'auto'},
            } as SxProps,
            text: {
                display: 'flex',
                flexDirection: 'column', 
                gap: {xs: 32, lg: 16}, 
                alignItems: 'center'
            } as SxProps,
            title: {
                ...(isXS ? landingH2 : landingH1)
            } as SxProps,
            copy: {
                ...(isXS ? landingCopySecondary : landingCopyPrimary), 
                color: theme.palette.darkPrimary.dark50, 
                textAlign: 'center' 
            } as SxProps,
            citing: {
                display: 'flex',
                flexDirection: 'column', 
                gap: 8,
                alignItems: 'center',
                maxWidth: '650px'
            } as SxProps,
            message: {
                display: 'flex',
                gap: 8,
                alignItems: 'center'
            } as SxProps,
            chevron: {
                color: secondaryDefault,
                fontFamily: 'Poppins',
                fontSize: '60px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '60px',
                textTransform: 'uppercase'
            } as SxProps,
            messageCopy: {
                ...landingH2, 
                color: secondaryDefault, 
                textAlign: 'center', 
            } as SxProps,
            author: {
                ...landingH4,
                color: theme.palette.darkPrimary.dark50, 
                textAlign: 'center'
            } as SxProps,
            people: {
                width: '100%',
                display: 'flex',
                mx: 'auto',
                gap: 16,
                justifyContent: 'space-evenly',
            } as SxProps,
            person: {
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                alignItems: 'center',
                px: 8,
                mb: {xs: 16, md: 0}
            } as SxProps,
            personName: {
                ...landingH3, 
                color: secondaryDefault,
                display: 'flex',
                gap: 8,
                alignItems: 'center'
            } as SxProps, 
            personDescription: {
                ...landingCopySecondary,
                textAlign: 'center',
                width: {xs: 'auto', sm: '35%', md: 'auto'},
                color: theme.palette.darkPrimary.dark50
            } as SxProps,
            picture: {
                width: '82px',
                height: '82px',
                borderRadius: '41px',
                overflow: 'hidden'
            } as SxProps
        }

        return <> 
            <Box sx={styles.root} ref={WhoAreWeRef} data-index={sections.WhoWeAre}>
                <Box sx={styles.content}>
                    <Box sx={styles.text}>
                        <Box sx={styles.title}>
                            about us
                        </Box>

                        <Box>
                            <Typography sx={styles.copy}>We are serial entrepreneurs who raised millions of venture capital.</Typography>
                            <Typography sx={styles.copy}>We built Pitchura for our younger selves. To give to others the help we didn’t have.</Typography>
                        </Box>
                    </Box>

                    <Box sx={styles.citing}>
                        <Box sx={styles.message}>
                            <Box sx={styles.chevron}>
                                «
                            </Box>

                            <Box sx={styles.messageCopy}>
                                The best thing a human being can do is to help another human being know more.
                            </Box>

                            <Box sx={styles.chevron}>
                                »
                            </Box>
                        </Box>

                        <Box sx={styles.author}>
                            Charlie Munger (1924-2023)
                        </Box>
                    </Box>

                    <Box sx={styles.people}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Box sx={styles.person}>
                                    <Box sx={styles.picture}>
                                        <XJImage src={"/img/miki.jpg"} />
                                    </Box>

                                    <Box sx={styles.personName}>
                                        <Box>Miki Mullor </Box>
                                        <LinkedInButton profileURL={'https://www.linkedin.com/in/mikimullor/'} />
                                    </Box>

                                    <Box sx={styles.personDescription}>
                                        Jobaline, Ancora Technologies, Microsoft, three other start ups, IDF 8200, Reichman University (Israel)
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={styles.person} >
                                    <Box sx={styles.picture}>
                                        <XJImage src={"/img/jose.jpg"} />
                                    </Box>

                                    <Box sx={styles.personName}>
                                        <Box> Jose Contreras</Box>
                                        <LinkedInButton profileURL={'https://www.linkedin.com/in/josedanielcontreras/'} />
                                    </Box>

                                    <Box sx={styles.personDescription}>
                                        Microsoft, Jobaline, SAP, 100ThingsClub, University of Miami
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    }

    const FAQ = () => {

        return <>
            <Box
                ref={FAQRef}
                data-index={sections.FAQ}
            >
                <Box width={'50%'} mx={'auto'} mt={50}>
                    <Box mx={'auto'} mb={32} sx={{ ...landingH1, color: secondaryDefault }}>FAQ </Box>

                    <Box sx={{ ...landingCopyPrimary }} >
                        NDA <br />
                        confidentiality,<br />
                        VC are do not want to build.<br />
                        Vcs are investors finance people not builders<br />
                    </Box>
                </Box>

                <Box height={100}>

                </Box>
            </Box>
        </>

    }

    const Footer = () => {

        const onEmailClick = (event: any) => {
            window.location.href = 'mailto:info@pitchura.ai'
            event.preventDefault()
        }

        const styles = {
            root: {
                borderTop: '2px solid ' + whiteOverlay60,
                backgroundColor: whiteOverlay16,
                width: '100%',
                height: 'auto',
                pb: {xs: 4, md: 32}
            } as SxProps, 
            links: {
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                gap: {xs: 4, md: 16}, 
                justifyContent: 'center',
                alignItems: 'center',
                py: 12
            } as SxProps,
            link: {
                ...landingLinkSecondaryDefault,
                color: secondaryDefault
            },
            copyright: {
                ...landingCopySecondary,
                display: 'flex',
                justifyContent: 'center',
                color: theme.palette.darkPrimary.dark50
            } as SxProps
        }

        return <Box sx={styles.root}>
            <Box sx={styles.links}>
                <XJLink variant={LinkVariant.secondary} sx={styles.link} to={'/terms'}>Terms of Use</XJLink>
                <XJLink variant={LinkVariant.secondary} sx={styles.link} to={'/privacy'}>Privacy</XJLink>
                <XJLink variant={LinkVariant.secondary} sx={styles.link} color={whiteDefault} to={''} onClick={onEmailClick}>info@pitchura.ai</XJLink>
            </Box>

            <Box sx={styles.copyright}>
                2024 Pitchura Inc. All Rights Reserved ©
            </Box>
        </Box>
    }

    const styles = {
        root: {
            width: '100%',
            position: 'relative',
            backgroundColor: whiteOverlay16
        } as SxProps
    }


    return (
        <Box sx={styles.root}>
            <NavBar />
            {Hero()}
            {WhyPitchura()}

            {Package()}
            {Pricing()}
            {WhoAreWe()}

            {/* {FAQ()} */}
            {Footer()}
        </Box>
    )
}