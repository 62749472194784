import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CustomContentProps } from "notistack"
import React from "react";
import { HeadlineH3, secondaryDefault, whiteDefault } from "XJumpTheme";
import { BaseToastComponent, styleToastComponent } from "./BaseToastComponent";

export const ToastWorkingComponent = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {

    return <BaseToastComponent ref={ref} {...props}>
        <Box sx={{ ...styleToastComponent, backgroundColor: secondaryDefault, pl: '32px'}} >
            <Box>
                <Typography sx={{...HeadlineH3}} color={whiteDefault}>
                   working...
                </Typography>
            </Box>
        </Box>
    </BaseToastComponent>
})

