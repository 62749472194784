import { useCallback, useEffect, useRef, useState } from 'react'
import { Prompt, useLocation, useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom"

import { SegmentModel } from 'Model/SegmentModel'
import {
    DragDropContext,
    Droppable,
    DropResult
} from 'react-beautiful-dnd'

import { usePitches } from 'hooks/UsePitches'
import XJBreadcrumbs from '../../../UI Elements/XJBreadcrumbs'
import { SegmentCard } from './XJSegmentCard'
import { XJButton } from '../../../UI Elements/Buttons/XJButton'
import { Box, Grid, InputAdornment, SxProps, Typography } from '@mui/material'
import XJStatusBadge, { BadgeColor } from 'UI Elements/XJStatusBadge'
import XJTabs from 'UI Elements/XJTabs'

import XJInputBox from 'UI Elements/XJInputBox'
import { useKeyPress } from 'hooks/UseKeyPress'
import { darkPrimaryDefault, Header, HeaderRightButtons, PageTitle, secondaryDefault, whiteDefault, copySecondaryRegular, copySecondaryBold, MainContent, SVGStyle } from 'XJumpTheme'
import { IPublicPitch, IVideoSegment, PitchPlayerAPI, PitchPlayer } from 'Pages/Video/Player/PitchPlayer'
import { PitchModel } from 'Model/PitchModel'
import { mapVisualAidModel } from 'Pages/Video/Player/VideoPlayer'
import { XJPitchIndustry } from 'Components/XJPitchIndustry'
import { XJPitchRound } from 'Components/XJPitchRound'
import { CompanyStage, PitchRound, PitchStatus, SegmentStatus } from 'Common/Enums'
import { XJPitchStage } from 'Components/XJPitchStage'
import { XJPitchTag } from 'Components/XJPitchTag'
import { useAlert } from 'hooks/UseAlert'
import { PitchStudioSection, RoutingController } from 'Controllers/RoutingController'
import { throttle } from 'lodash'
import { ExplanationBox } from 'UI Elements/XJExplanationBox'

import XJConfirmationDialog from 'UI Elements/PopUps/XJConfirmationDialog'
import { LogError } from 'Controllers/Logging'

import { ReactComponent as WarningIcon} from '../../../svg/warning-1.svg'
import XJExplainer from 'UI Elements/PopUps/XJExplainer'
import { ExplainerID } from 'hooks/UseExplainersShouldShow'
import { NotFoundBanner } from 'UI Elements/NotFoundBanner'

interface SegmentTabProps {
    pitch: PitchModel
}

const SegmentTab = (props: SegmentTabProps) => {
    
    const pitch = props.pitch

    const throttledReorderedSave = throttle(async () => {
        // call save no more than once a second 
        await Promise.allSettled(
            pitch.segments.map(seg => seg.save())
        )
        pitch.save()
    }, 1000)

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }

        pitch.segments = reorder(
            pitch.segments,
            result.source.index,
            result.destination.index
        )

        throttledReorderedSave()
    }

    function reorder(list: SegmentModel[], startIndex: number, endIndex: number): SegmentModel[] {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result.map<SegmentModel>((x, index) => {
            x.sequence = index
            return x
        })
    }

    return <>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="segmentList" direction="horizontal">
                {(provided) => (
                    <div {...provided.droppableProps}
                        ref={provided.innerRef} >
                        <Box display='flex'
                            flexWrap="wrap"
                            className="segmentList"
                        >
                            {pitch.segments.sort(
                                (a: SegmentModel, b: SegmentModel) => {
                                    if (a.sequence < b.sequence)
                                        return -1
                                    else return 1
                                })
                                .map((x: SegmentModel) => SegmentCard(x))}

                            {provided.placeholder}
                        </Box>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </>
}

interface VideoTabProps {
    pitch: PitchModel
}

function VideoTab(props: VideoTabProps) {

    // MARK: - State 

    const pitch = props.pitch

    const playerRef = useRef<PitchPlayerAPI>()
    const player = useCallback(() => {
        return playerRef.current
    }, [])

    useEffect(() => {
        if (player()) {
            player()?.preload()
        }
    }, [player])

    const [activeSegment, setActiveSegment] = useState<SegmentModel | undefined>(undefined)


    // MARK: - Actions 

    const onSegmentChanged = useCallback((currentSegmentId: number, prevSegmentId?: number | null) => {
        const newSegment = pitch.segments.find((seg) => { return seg.sequence === currentSegmentId })
        if (newSegment) {
            setActiveSegment(newSegment)
        }
    }, [pitch.segments])

    const onSegmentItemChanged = (clickedSegment: SegmentModel) => {
        player()?.seekToSegment(clickedSegment.sequence)
    }

    const onSegmentListItemClicked = (clickedSegmentSequence: number) => {
        player()?.seekToSegment(clickedSegmentSequence)
    }


    // MARK: - Keyboard 

    // Keyboard action handling

    const kSpaceKey = " "
    const kArrowUpKey = "ArrowUp"
    const kArrowDownKey = "ArrowDown"
    const kArrowLeftKey = "ArrowLeft"
    const kArrowRightKey = "ArrowRight"

    const handleArrowUpDown = useCallback((up: boolean) => {
        if (activeSegment) {
            const index = pitch.finalVideo.indexOf(activeSegment)
            let newIndex = undefined
            if (up && index > 0) {
                newIndex = index - 1
            }
            if (!up && index < pitch.finalVideo.length - 1) {
                newIndex = index + 1
            }

            if (newIndex !== undefined && newIndex >= 0 && newIndex <= pitch.finalVideo.length - 1) {
                const newSegment = pitch.finalVideo[newIndex]
                if (activeSegment.id !== newSegment.id) {
                    setActiveSegment(newSegment)
                    player()?.seekToSegment(newSegment.sequence)
                }
            }
        }
    }, [activeSegment, pitch, player])

    const onKeyDown = useCallback((key: string) => {
        switch (key) {
            case kSpaceKey:
                player()?.state().playing ? player()?.pause() : player()?.play()
                break

            case kArrowUpKey:
                handleArrowUpDown(true)
                break

            case kArrowDownKey:
                handleArrowUpDown(false)
                break

            case kArrowLeftKey:
                player()?.rewind(5)
                break

            case kArrowRightKey:
                player()?.forward(5)
                break

            default:
                break
        }
    }, [handleArrowUpDown, player])

    useKeyPress({ char: kSpaceKey }, onKeyDown)
    useKeyPress({ char: kArrowUpKey }, onKeyDown)
    useKeyPress({ char: kArrowDownKey }, onKeyDown)
    useKeyPress({ char: kArrowLeftKey }, onKeyDown)
    useKeyPress({ char: kArrowRightKey }, onKeyDown)


    // MARK: - Utils 

    const _mapSegmentModel = (segment: SegmentModel) => {
        return {
            seq: segment.sequence,
            title: segment.title,
            duration: segment.video ? segment.video.duration : 0,
            url: segment.video ? segment.video.getMediaLocalBlobUrl() : '' ,
            visualAids: segment.video ? segment.video.visualAids.map((aid) => mapVisualAidModel(aid)) : [],
            model: segment.video ? segment.video : undefined
        } as IVideoSegment
    }

    const _mapPitch = (aPitch: PitchModel) => {
        return {
            id: aPitch.id ?? 0,
            segments: aPitch.finalVideo.filter(seg => seg.hasVideo).map(seg => _mapSegmentModel(seg))
        } as IPublicPitch
    }


    // MARK: - Render 

    const NoVideo = () => {
        return <>
            <Box py={32} px={64} sx={{ backgroundColor: whiteDefault }} width={'75%'}>

                <Box display={'flex'} flexDirection={'column'} gap={16} alignItems={'center'} my={16} >
                    <Box  >
                       <WarningIcon style={SVGStyle}/>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={8} alignItems={'center'} >
                        <Box>
                            <Typography variant='h2' color= {secondaryDefault}>
                                Video not recorded yet
                            </Typography>
                        </Box>
                        <Box mb={16}  >
                            <ExplanationBox centered>
                                Once you recorded videos for each segment you have a script for, <br /> the final pitch video will show here.
                            </ExplanationBox>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    }

    const SegmentItemSX = {
        root: {
            display: 'flex',
            py: 8,
            px: 16,
            backgroundColor: whiteDefault,
            color: darkPrimaryDefault,
            justifyContent: 'space-between',
            marginBottom: 8,
            cursor: 'pointer',
            ...copySecondaryRegular
        } as SxProps
    }

    return <>
        <Grid container>
            {!pitch.hasFinalVideo &&
                <Grid item xs={8}>
                    <NoVideo />
                </Grid>}

            {pitch.hasFinalVideo &&
                <Grid item md={8} xs={12}>
                    <PitchPlayer
                        ref={(ref) => { playerRef.current = ref! }}
                        pitch={_mapPitch(pitch)}
                        onSegmentChange={onSegmentChanged}
                        hideControls={true}
                        hideTimeline={true}
                        showOnScreenControls={true}
                    />
                </Grid>}

            {pitch.hasFinalVideo &&
                <Grid item md={4} >
                    <Box ml={16} display={{ xs: 'none', md: 'block' }} >
                        {pitch.segments.filter(x => x.video && x.video?.duration > 0).map((segment, index) => {
                            const minsSecs = SegmentModel.videoDurationInMinsSecs(segment.video!.duration)
                            return <Box sx={SegmentItemSX.root} key={`segment${segment.sequence}`} onClick={() => onSegmentListItemClicked(segment.sequence)}>
                                <Box sx={activeSegment?.sequence === segment.sequence ? { ...copySecondaryBold, color: secondaryDefault } : null}> {index + 1}: {segment.title} </Box>
                                <Box >{`${minsSecs.mins}:${String(minsSecs.secs).padStart(2, '0')}`}</Box>
                            </Box>
                        })}
                    </Box>
                </Grid>}
        </Grid>
    </>
}

interface AboutPaneProps {
    pitch: PitchModel
}

const AboutPane = (props: AboutPaneProps) => {

    const pitch = props.pitch

    const [stage, setStage] = useState<CompanyStage | null>()
    const [round, setRound] = useState<PitchRound | null>()
    const [industry, setIndustry] = useState<string | null>()
    const [subIndustry, setSubIndustry] = useState<string | null>()
    const [tag, setTag] = useState<string[] | null>()
    const [form, setForm] = useState<{ ['Internal Title']: string, ['Public Title']: string, ['Round Amount']: number }>()

    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        setStage(pitch.stage)
        setRound(pitch.round)
        setIndustry(pitch.industry)
        setSubIndustry(pitch.subIndustry)
        setTag(pitch.tag)
        setForm({ 'Internal Title': pitch.title, "Public Title": pitch.publicTitle, "Round Amount": pitch.roundAsk })

        const changed = pitch.updateStatus()
        if (changed) {
            pitch.save()
        }

    }, [pitch])

    // MARK: - Actions 

    const onSaveAbout = async () => {

        const alert = useAlert()

        pitch.industry = industry ? industry : null
        pitch.subIndustry = subIndustry ? subIndustry : null
        pitch.stage = stage ? stage : null
        pitch.round = round ? round : null
        pitch.tag = tag ? tag : null
        pitch.title = form?.['Internal Title'] ? form?.['Internal Title'] : ''
        pitch.publicTitle = form?.['Public Title'] ? form?.['Public Title'] : ''
        pitch.roundAsk = Number(form?.['Round Amount'])

        pitch.save()
            .then(res => {
                alert.successSave()
                setIsDirty(false)
            })
            .catch(err => {
                LogError(err)
                alert.APIError()
            })
    }

    const formChanged = () => {
        setIsDirty(true)
    }

    const onIndustryChanged = (newValue: string | null) => {
        if (newValue !== industry) {
            setIndustry(newValue ?? undefined) 
            setSubIndustry(undefined)
            formChanged()
        }
    }

    const onSubIndustryChanged = (newValue: string | null) => {
        if (newValue !== subIndustry) {
            setSubIndustry(newValue ?? undefined)
            formChanged()
        }
    }

    const onRoundChanged = (newValue: PitchRound | null) => {
        if (newValue !== round) {
            setRound(newValue ?? undefined)
            formChanged()
        }
    }

    const onStageChanged = (newValue: CompanyStage | null) => {
        if (newValue !== stage) {
            setStage(newValue ?? undefined)
            formChanged()
        }
    }

    const onTagChange = (newValue: string[] | undefined) => {
        if (newValue && !newValue.isEmpty()) {
            if (newValue !== tag) {
                setTag([newValue[newValue.length - 1]])   // takes last selected 
                formChanged()
            }
        } else if (tag && !tag.isEmpty()) {
            setTag(null)
            formChanged()
        }
    }

    return <Box display={'flex'} flexDirection='column'>

        {/*  <Box>

                {pitch.CoverImage ? <XJImage src={pitch.CoverImage} /> : <Box width={'500px'} height={'300px'}  ></Box>

                }

                <XJButton Variant={'secondary'} onClick={() => onCoverImageEdit()}>Edit</XJButton>

            </Box> */}


        <Prompt
            message={'There are unsaved changed to the pitch About. Are you sure you want to continue?'}
            when={isDirty}

        />
        <Box display={'flex'} gap={16}>
            <XJInputBox autofocus name={'Internal Title'} value={form!} setValue={setForm} width={'250px'} onChange={formChanged}  ></XJInputBox>
            <XJInputBox name={'Public Title'} value={form!} setValue={setForm} width={'250px'} onChange={formChanged}></XJInputBox>
            <Box display={'flex'} justifyContent='flex-end' width={'100%'}>
                <XJButton
                    disabled={!isDirty}
                    onClick={onSaveAbout} variant={'secondary'}>Save</XJButton>
            </Box>
        </Box>

        <Box display={'flex'} gap={16}>
            <Box mb={16} >
                <Box mb={8}>
                    <Typography variant='h3'>
                        Industry
                    </Typography>
                </Box>
                <XJPitchIndustry
                    industry={industry ? [industry, subIndustry ? subIndustry : null] : null}
                    onIndustryChanged={onIndustryChanged}
                    onSubIndustryChanged={onSubIndustryChanged}
                    SideBySide
                    autoWidth
                />
            </Box>
        </Box>

        <Box display={'flex'} gap={16}>
            <Box mb={16}>
                <Box mb={8}>
                    <Typography variant='h3'>
                        Round
                    </Typography>
                </Box>
                <XJPitchRound
                    round={round ? round : null}
                    autoWidth
                    onRoundChanged={onRoundChanged}
                />
            </Box>

            <XJInputBox
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                key='ask'
                name="Round Amount"
                value={form!}
                setValue={setForm}
                width='250px'
                onChange={formChanged}
            />
        </Box>

        <Box display={'flex'} gap={8}>
            <Box mb={16}>
                <Box mb={8}>
                    <Typography variant='h3'>
                        Company Stage
                    </Typography>
                </Box>
                <XJPitchStage
                    stage={stage ? stage : null}
                    onStageChanged={onStageChanged}
                    autoWidth
                />
            </Box>
        </Box>

        <Box display={'flex'} gap={8}>

            <Box mb={16} width={'400px'}>
                <Box mb={8}>
                    <Typography variant='h3'>
                        Tag
                    </Typography>
                </Box>
                <XJPitchTag
                    selectedTag={tag ?? undefined}
                    autoWidth
                    onChangedSelectedTag={onTagChange}
                />
            </Box>
        </Box>
    </Box >
}

export function PitchEditor() {

    const history = useHistory<any>()
    const { pid } = useParams<any>()
    const pitchId = Number(pid)

    const pitchesHook = usePitches()
    const pitch = pitchesHook.getPitch(pitchId)  // PitchModel.getPitchById(props.pitches, pitchId) 

    const alert = useAlert()
    const successAlert = alert.success

    // default section 

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const section = params.get('tab')

    let InitTabIndex = 0

    if (section) {

        switch (Number(section)) {

            case PitchStudioSection.segments:
                InitTabIndex = 0
                break

            case PitchStudioSection.video:
                InitTabIndex = 1
                break

            case PitchStudioSection.about:
                InitTabIndex = 2
                break
        }
    }


    // MARK: - Actions 

    const onGoLive = () => {
        // show the modal confirmation 
        setShowMissingSegmentsConfirmation(true)
    }

    const handleGoLive = () => {
        history.push(RoutingController.PitchGoLiveProfile(pitch))
    }

    const handleUnpublish = () => {
        pitch.setStatusAndSave(PitchStatus.offline)
        successAlert('Pitch is offline')
        history.push(RoutingController.Home())
    }

    const handlePublish = () => {
        pitch.setStatusAndSave(PitchStatus.live)
        successAlert('Pitch is live')
        history.push(RoutingController.Home())
    }


    // MARK: - Render 

    const ActionButtons = () => {

        // should have at least one video in order to be published 
        const canBePublished = pitch.segments.some((seg) => seg.status === SegmentStatus.done)

        const renderPublishButton = () => {
            // check for live and unpublished statuses for specific actions - otherwise shows Go Live button
            switch (pitch.status) {
                case PitchStatus.live: {
                    return (
                        <XJButton variant={'secondary'} onClick={handleUnpublish}>
                            Go Offline
                        </XJButton>)
                }

                case PitchStatus.offline: {
                    return (
                        <XJButton variant={'secondary'} onClick={handlePublish}>
                            Go Live
                        </XJButton>)
                }

                default: {
                    return (
                        <XJButton variant={'primary'} disabled={!canBePublished} onClick={onGoLive}>
                            Go Live 
                        </XJButton>)
                }
            }
        }

        return <Box sx={HeaderRightButtons}>
            <XJButton variant={'tertiary'} onClick={() => { history.push(RoutingController.Home()) }}>Back</XJButton>
            {renderPublishButton()}
        </Box>
    }

    const [showMissingSegmentsConfirmation, setShowMissingSegmentsConfirmation] = useState(false)

    const explainerCondition = useCallback(() => {
        return pitch && pitch.status === PitchStatus.script
    }, [])

    return pitch ? <>

        <XJExplainer
            id={ExplainerID.PitchStudio}
            title={'Pitch Studio'}
            welcomeText={"This is your Pitch Studio. Right now your pitch is being created by our AI. You will get a notification on your email when it's done. Meanwhile you are welcome to explore Pitch Stuido features"}
            
            conditionFn={explainerCondition}
        />

        <XJConfirmationDialog
            open={showMissingSegmentsConfirmation}
            onCancel={() => { setShowMissingSegmentsConfirmation(false) }}
            onConfirm={handleGoLive}

            title={'Missing Segments'}
            message={'Some segments in your pitch do not have videos. <br/> Are you sure you want to continue?  <br/> (segments without videos will be exlcuded from the pitch video)'}
            ConfirmButtonText={"I'm sure"}
            CancelButtonText={'Back'}
            SVGComponent={<WarningIcon/>}
        />

        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <ActionButtons />
            </Grid>

            <Grid item xs={12} sx={PageTitle}>
                <Typography variant='h1' color='secondary'>
                    {pitch.title}
                </Typography>
                <XJStatusBadge type={BadgeColor.blue} text={pitch.getStatusLabel()} />
            </Grid>
            
            <Grid item xs={8} >
                <ExplanationBox>
                    Every pitch is made of several segments, which will play seamlessly when an investor plays the final video. From here you record each segment. You can record several or all segments, or re-order them in the final video by a drag-n-drop.
                </ExplanationBox>
            </Grid>

            <Grid item xs={12} sx={MainContent}>
                <XJTabs key='panels' index={InitTabIndex}>
                    <Box id={'Segments'}>
                        <SegmentTab pitch={pitch} />
                    </Box>
                    <Box id={'Video'}>
                        <VideoTab pitch={pitch} />
                    </Box>
                    <Box id={'About'}>
                        <AboutPane pitch={pitch} />
                    </Box>
                </XJTabs>
            </Grid>
        </Grid>
    </> 
    :
    <NotFoundBanner 
        text='This pitch cannot be accessed'
        urlText='Go home'
        url={RoutingController.Home()}
    />
}