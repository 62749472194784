
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { closeSnackbar, CustomContentProps } from "notistack"
import React from "react";
import XJIconButton from "UI Elements/Buttons/XJIconButton";
import { ToastErrorClose } from "UI Elements/XJIcons";
import { denotiveErrorDefault, HeadlineH3, whiteDefault } from "XJumpTheme";
import { BaseToastComponent, styleToastComponent } from "./BaseToastComponent";

export const ToastErrorComponent = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {

    return <BaseToastComponent ref={ref} {...props}>
        <Box sx={{ ...styleToastComponent, backgroundColor: denotiveErrorDefault, pr: '0px', gap: '24px' }} >
            <Box>
                <Typography sx={{ ...HeadlineH3 }} color={whiteDefault}>
                    {props.message}
                </Typography>
            </Box>

            <XJIconButton onClick={() => { closeSnackbar(props.id) }} transparent sx={{boxShadow: '-1px 0px 0px 0px rgba(255, 255, 255, 0.20)', borderRadius: 0}}>
                <ToastErrorClose />
            </XJIconButton>
        </Box>
    </BaseToastComponent>
})


