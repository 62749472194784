import { QuestionModel } from "./QuestionModel"
import { SegmentModel } from "./SegmentModel"
import LinkModel from "./LinkModel"
import { UserModel } from "./UserModel"
import { TopicModel } from "Model/TopicModel"
import { PitchTemplateModel } from "./PitchTemplateModel"
import { PitchDAO } from "Common/PitchDAO"
import API from "api-axios"
import { QT, QT_Null } from "./Types"
import { CompanyStage, PitchRound, PitchStatus, PitchType, SegmentStatus, TopicStatus } from "Common/Enums"
import { SendPitchToAIScript } from "Controllers/AdminController"
import { LogError } from "Controllers/Logging"

export class PitchModel {


  //  ----->   Data members 

  // private 

  private _id: number | undefined
  private _coverImage = ''
  private _tag: string | null = null
  private _QAComplete = false
  private lastAskedQuestionId: number | null
  private lastAskedTopicID: number | null
  private _status: PitchStatus = PitchStatus.new

  // public 

  template: PitchTemplateModel | undefined
  type: PitchType | undefined
  title = ""
  founder: UserModel | undefined
  logo = ""
  percentPlayed = 0
  lastPlayed: Date | null = null
  publicTitle = ""
  segments: SegmentModel[] = []
  industry: string | null = null
  subIndustry: string | null = null
  stage: CompanyStage | null = null
  round: PitchRound | null = null
  roundAsk = 0
  Deleted = false
  _links: LinkModel[] = []


  // data members access 

  get id(): number | undefined {
    return this._id
  }

  set CoverImage(value: string) {
    this._coverImage = value
    this.save()
  }

  get CoverImage() {
    return this._coverImage
  }

  public get QAComplete(): boolean {
    return this._QAComplete
  }
  public set QAComplete(value: boolean) {
    this._QAComplete = value
    this.save()
  }

  set tag(value: string[] | null) {
    this._tag = value ? PitchModel.packTag(value) : null

  }

  get tag(): string[] | null {
    return this._tag ? PitchModel.unpackTags(this._tag) : null
  }

  set status(newStatus: PitchStatus) {
    this._status = newStatus
    //this.save()
  }

  get status() {
    return this._status

  }

  // Database actions 

  public async reload() {

    if (this.id) {
      const pitchData = (await API.get<PitchDAO>('/pitch', { params: { pid: this.id } })).data
      this.load(pitchData)
    }

  }

  public load(p: PitchDAO) {

    this._id = p.id
    this.title = p.title!
    this._status = p.status!
    this.publicTitle = p.publicTitle!
    this.type = p.pitchType as PitchType

    this.lastAskedQuestionId = p.lastAskedQuestionId
    this.lastAskedTopicID = p.lastAskedTopicID

    this.round = p.round
    this.industry = p.industry
    this.roundAsk = p.roundAsk
    this.stage = p.stage
    this._status = p.status
    this.subIndustry = p.subIndustry
    this._tag = p.tag
    this.lastPlayed = p.lastPlayed
    this._QAComplete = p.QAComplete


  }

  public async save() {

    this.updateStatus()

    try {

      const dao = this.packPitchDAO()

      if (this.id) {

        //update   

        await API.post(`/pitch/${this.id}/update`, dao)

      } else {

        // create a new one  

        this._id = await (await API.post('/pitch/create', dao)).data.id

      }

    } catch (error) {

      LogError(error)
      return Promise.reject(error)
    }
  }

  public packPitchDAO = (): PitchDAO => {
    return {

      pitchType: this.type,
      title: this.title,
      status: this.status,
      lastPlayed: this.lastPlayed,

      lastAskedTopicID: this.lastAskedTopicID,
      lastAskedQuestionId: this.lastAskedQuestionId,

      publicTitle: this.publicTitle,
      id: this.id,
      templateId: this.template?.Id,
      round: this.round,
      stage: this.stage,
      industry: this.industry,
      subIndustry: this.subIndustry,
      tag: this._tag,
      roundAsk: this.roundAsk,
      QAComplete: this.QAComplete,

    } as PitchDAO
  }


  static packTag(tags: string[]): string {

    return tags.join('|')

  }

  static unpackTags(tag: string): string[] {

    return tag.split('|')

  }

  get links(): LinkModel[] {
    return this._links.filter(link => link.deleted === false)
  }

  get linksCount(): number {
    return this.links.length
  }

  get totalViews(): number {
    return this.links.reduce((prev, cur) => {
      return prev + cur.viewsCount
    }, 0)
  }

  async getNewShareLink(title: string): Promise<LinkModel> {
    const link = new LinkModel(this)
    await link.new(title)

    return link
  }

  getStatusLabel(): string {
    switch (this.status) {

      case PitchStatus.new:
        return "New"

      case PitchStatus.draft:
        return "Q&A"

      case PitchStatus.script:
        return "Script"

      case PitchStatus.ready:
        return "Ready"

      case PitchStatus.live:
        return "Live"

      case PitchStatus.offline:
        return "Offline"

      default:
        return "undefined"
    }
  }


  //  ------> Status actions  


  public async toggleLiveStatus(): Promise<void> {

    if (this.status !== PitchStatus.live && this.status !== PitchStatus.offline) {

      const errorMsg = `Pitch::toggleLiveStatus attempt to toggle pitch id ${this.id} but status is ${this.getStatusLabel()}`
      LogError(errorMsg)
      throw (errorMsg)

    }

    const oldStatus = this.status
    this.status = this.status === PitchStatus.live ? PitchStatus.offline : PitchStatus.live

    return this.save()

      .then(res => {

        Promise.resolve()

      })
      .catch(err => {

        this.status = oldStatus
        const errorMsg = `Pitch::toggleLiveStatus attempt to toggle pitch id ${this.id}`
        LogError(errorMsg)
        LogError(err)

        throw (new Error(errorMsg))


      })

  }


  public updateStatus = () => {

    let changed = false

    if (this.status === PitchStatus.live || this.status === PitchStatus.offline) return false

    if (this.status === PitchStatus.draft &&
      this.segments.some(x => x.status === SegmentStatus.pending) &&
      this.segments.some(x => x.status !== SegmentStatus.done))
    {
      this.status = PitchStatus.script
      return true
    }

    if (this.segments.some(x => x.status === SegmentStatus.done))
    {
      changed = this.status !== PitchStatus.ready
      this.status = PitchStatus.ready
      return changed
    }

    return changed
  }



  // -------> Questions 

  public Questions(): QuestionModel[] {
    const Questions: QuestionModel[] = []

    this.segments.map((x) => {
      Questions.push(...x.getAllQuestions())
      return x
    })

    return Questions
  }

  private Topics(): TopicModel[] {
    const Topics: TopicModel[] = []

    this.segments.map((x) => {
      Topics.push(...x.getAllTopics())
      return x
    })

    return Topics
  }

  async setStatusAndSave(status: PitchStatus) {
    const oldStatus = this.status
    this.status = status

    await this.save()

      .catch(err => {

        this.status = oldStatus

      })
  }

  public updateLastAskedQuestion(qt: QT_Null) {

    let needsSaving = false

    if (qt === null) {
      if (this.lastAskedQuestionId !== null || this.lastAskedTopicID !== null) {
        needsSaving = true
      }

      this.lastAskedQuestionId = null
      this.lastAskedTopicID = null
    }

    else if (qt instanceof QuestionModel && qt.id !== undefined) {
      if (this.lastAskedQuestionId !== qt.id || this.lastAskedTopicID !== qt.topic.id) {
        needsSaving = true
      }

      this.lastAskedQuestionId = qt.id
      this.lastAskedTopicID = qt.topic.id
    }

    else if (qt instanceof TopicModel) {
      if (this.lastAskedQuestionId !== null || this.lastAskedTopicID !== qt.id) {
        needsSaving = true
      }

      this.lastAskedQuestionId = null
      this.lastAskedTopicID = qt.id
    }
    else {

      // this should never happen 

      throw new Error("updateLastAskedQuestion: " + qt)
    }

    if (needsSaving)
      this.save()

  }

  getQuestion(id: number): QuestionModel {
    return this.Questions().find((x) => x.id === id) as QuestionModel
  }


  getTopic(id: number): TopicModel {
    return this.Topics().find((x) => x.id === id) as TopicModel
  }




  // ------>  Segments 

  public getNextSegment(S?: SegmentModel): SegmentModel | undefined {

    if (!S) {

      return this.segments.find(x => x.sequence === 0)

    }

    const segmendSqeuence = S.sequence

    for (let i = segmendSqeuence + 1; i < this.segments.length; i++) {

      // eslint-disable-next-line no-loop-func
      const NextSegment = this.segments.find(x => x.sequence === i)

      if (NextSegment && NextSegment.topics.length > 0) return NextSegment

    }

    return undefined

  }
/* 
  public hasSegments(): boolean {

    if (typeof this.segments === "undefined") return false

    if (this.segments.length === 0) return false

    return true
  } */

  public getSegmentById(id: number): SegmentModel {

    return this.segments.find(x => x.id === id) as SegmentModel

  }

  /* public getSegmentBySeq(seq: number): SegmentModel {

    return this.segments.find((x) => x.sequence === seq) as SegmentModel
  } */

/*   public getNewSegmentSequence(): number {

    if (this.segments.length === 0) return 0

    return Math.max(...this.segments.map(function (o) { return o.sequence })) + 1

  } */

  public getPrevSegment(S: SegmentModel): SegmentModel | undefined {

    const segmendSqeuence = S.sequence

    for (let i = segmendSqeuence - 1; i > -1; i--) {

      // eslint-disable-next-line no-loop-func
      const PrevSegment = this.segments.find(x => x.sequence === i)

      if (PrevSegment && PrevSegment.topics.length > 0) return PrevSegment

    }

    return undefined

  }



  // ------>  Interiew navigation 


  public async interviewGoNext(current: QT): Promise<QT_Null> {

    const next = current.segment.goNext(current)

    if (next) {

      // this.updateLastAskedQuestion(next) // commented out as last asked question is updated when question is shown 

      return next
    }
    else {
      current.segment.InterviewIsComplete()
    }

    const NextSegment = current.segment.getNextSegment()

    if (NextSegment) {

      const firstTopic = NextSegment.goFirstTopic()
      // this.updateLastAskedQuestion(firstTopic) // commented out as last asked question is updated when question is shown 
      return firstTopic

    }

    // Finishing interview 
    await this._finishInterview()

    return null
  }

  async _finishInterview() {

    await Promise.allSettled(
      this.segments.map(seg => seg.save())
    )

    await this.setStatusAndSave(PitchStatus.script)

    await SendPitchToAIScript(this)
      .then(result => {
        if (result) {
          this.reload()
        }
      })
      .catch(err => {
        LogError(err)
        throw err
      })

    this.updateLastAskedQuestion(null)
  }



  public interviewGoBack(current: QT): QT_Null {

    const back = current.segment.goBack(current)

    if (back) {

      this.updateLastAskedQuestion(back)
      return back
    }

    const prevSegment = current.segment.getPrevSegment()

    if (prevSegment) {

      const curentStep = prevSegment.getCurrentInterviewStep()
      this.updateLastAskedQuestion(curentStep)

      return curentStep

    }

    this.updateLastAskedQuestion(null)
    return null

  }


  public async interviewSkipTopic(current: TopicModel): Promise<QT_Null> {

    const nextTopic = current.segment.skipTopic(current)

    if (nextTopic) {

      this.updateLastAskedQuestion(nextTopic)
      return nextTopic
    }
    else {
      current.segment.InterviewIsComplete()
    }

    const NextSegment = current.segment.getNextSegment()

    if (NextSegment) {

      const topic = NextSegment.goFirstTopic()
      this.updateLastAskedQuestion(topic)
      return topic

    }

    // Finishing interview 
    await this._finishInterview()
    
    return null
  }


  public enterInterview(): QT_Null {

    if (!this.lastAskedQuestionId && !this.lastAskedTopicID) {

      const t = this.getNextSegment()

      if (t) {
        const topic = t.goFirstTopic()
        this.updateLastAskedQuestion(topic)
        return topic
      }
    }

    if (this.lastAskedQuestionId) {

      const q = this.getQuestion(this.lastAskedQuestionId)
      if (!q)
      {
        LogError(`enterInterview for Pitch id: ${this.id} couldn't find question id ${this.lastAskedQuestionId} in enterInterview() `)
      }
      
      return q
    }

    if (this.lastAskedTopicID && !this.lastAskedQuestionId)  // it's redundant to check nullability of question id but makes the code readable 
    {
      const t = this.getTopic(this.lastAskedTopicID)
      return t
    }

    // this should never happen 

    LogError(`unexpected error in enterInterview. Pitch id: ${this.id}`)
    return null
  }




  // -------->   Video 

  get hasFinalVideo(): boolean {
    return this.finalVideo.length > 0
  }

  get finalVideo(): SegmentModel[] {
    // TODO: implement with order of segments 

    const result = []

    for (const segment of this.segments) {
      if (segment.hasVideo()) {
        result.push(segment)
      }
    }

    return result
  }

  public getQAPercentCompleted(): number {

    // iterate through all segments 

    let completedTopics = 0
    let totalTopics = 0

    for (const s of this.segments) {

      for (const t of s.topics) {
        if (t.status === TopicStatus.complete) completedTopics = completedTopics + 1
        totalTopics = totalTopics + 1
      }

    }

    return totalTopics > 0 ? Math.round(completedTopics / totalTopics * 100) : 0

  }

}
