import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CustomContentProps } from "notistack"
import React from "react";
import { ToastSuccess } from "UI Elements/XJIcons";
import { HeadlineH3, secondaryDefault, whiteDefault } from "XJumpTheme";
import { BaseToastComponent, styleToastComponent } from "./BaseToastComponent";

export const ToastSuccessComponent = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {

    return <BaseToastComponent ref={ref} {...props}>
        <Box sx={{ ...styleToastComponent, backgroundColor: secondaryDefault, pl: '16px' }} >
            <ToastSuccess />
            <Box>
                <Typography sx={{...HeadlineH3}} color={whiteDefault}>
                    {props.message}
                </Typography>
            </Box>
        </Box>
    </BaseToastComponent>
})

